<template>
  <Form
    v-slot="{ values: formValues }"
    :initial-values="initialValues"
    :validation-schema="validationSchema"
    @submit="onSubmit"
  >
    <div class="column" style="max-width: 500px">
      <Field v-slot="{ errorMessage, value, field }" name="name">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Název semináře"
          v-bind="field"
        />
      </Field>
      <Field v-slot="{ value, field }" name="type">
        <p class="q-field__label q-mb-xs">Typ semináře</p>
        <div class="column">
          <q-radio
            :model-value="value"
            :val="SeminarType.Default"
            label="Klasický"
            v-bind="field"
          />
          <q-radio
            :model-value="value"
            :val="SeminarType.Idz"
            label="Implementace dlouhodobého záměru Kraje Vysočina"
            v-bind="field"
          />
          <q-radio
            :model-value="value"
            :val="SeminarType.Digcompedu"
            label="DigCompEdu"
            v-bind="field"
          />
        </div>
      </Field>
      <Field v-slot="{ errorMessage, value, field }" name="identifier">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Číslo semináře"
          v-bind="field"
        />
      </Field>
      <Field v-slot="{ errorMessage, value, field }" name="date">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :mask="dateTime_InputMask"
          :model-value="value"
          hint="Ve formátu 01. 01. 2023 14:00"
          label="Začátek semináře"
          v-bind="field"
        >
          <template v-slot:prepend>
            <q-icon class="cursor-pointer" name="event">
              <q-popup-proxy
                ref="qDateProxy"
                transition-hide="scale"
                transition-show="scale"
              >
                <q-date
                  :mask="dateTime_DateMask"
                  :model-value="value"
                  minimal
                  v-bind="field"
                  @update:model-value="hideDatePicker"
                >
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>

          <template v-slot:append>
            <q-icon class="cursor-pointer" name="access_time">
              <q-popup-proxy transition-hide="scale" transition-show="scale">
                <q-time
                  :mask="dateTime_DateMask"
                  :model-value="value"
                  format24h
                  v-bind="field"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup color="primary" flat label="Zavřít" />
                  </div>
                </q-time>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="endDate">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :mask="dateTime_InputMask"
          :model-value="value"
          hint="Ve formátu 01. 01. 2023 14:00"
          label="Konec semináře"
          v-bind="field"
        >
          <template v-slot:prepend>
            <q-icon class="cursor-pointer" name="event">
              <q-popup-proxy
                ref="qDateProxy"
                transition-hide="scale"
                transition-show="scale"
              >
                <q-date
                  :mask="dateTime_DateMask"
                  :model-value="value"
                  minimal
                  v-bind="field"
                  @update:model-value="hideDatePicker"
                >
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>

          <template v-slot:append>
            <q-icon class="cursor-pointer" name="access_time">
              <q-popup-proxy transition-hide="scale" transition-show="scale">
                <q-time
                  :mask="dateTime_DateMask"
                  :model-value="value"
                  format24h
                  v-bind="field"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup color="primary" flat label="Zavřít" />
                  </div>
                </q-time>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </Field>

      <Field
        v-slot="{ errorMessage, value, field }"
        class="q-mt-sm"
        name="lector"
      >
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Lektor"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="accreditation">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Akreditace"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="city">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Město"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="duration">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Doba trvání"
          v-bind="field"
        />
      </Field>

      <Field
        v-if="formValues.type === SeminarType.Default"
        v-slot="{ errorMessage, value, field }"
        name="price"
      >
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Cena v Kč"
          v-bind="field"
        />
      </Field>

      <Field v-slot="{ errorMessage, value, field }" name="capacity">
        <q-input
          :error="!!errorMessage"
          :error-message="errorMessage"
          :model-value="value"
          label="Max počet účastníků"
          type="number"
          v-bind="field"
        />
      </Field>
    </div>

    <q-btn
      :loading="isSubmitting"
      class="q-mt-md"
      color="secondary"
      type="submit"
    >
      Uložit
    </q-btn>
  </Form>
</template>

<script lang="ts" setup>
import { Field, Form } from "vee-validate";
import type { SeminarFormValues } from "@/components/seminar/SeminarForm/seminar-form";
import { validationSchema } from "@/components/seminar/SeminarForm/seminar-form";
import { ref } from "vue";
import {
  dateTime_DateMask,
  dateTime_InputMask,
} from "@/components/seminar/translators/date";
import { SeminarType } from "@/api/graphql/codegen/graphql";

const props = defineProps<{
  seminar?: Partial<SeminarFormValues>;
  isSubmitting?: boolean;
}>();

const qDateProxy = ref<any>(null);

const hideDatePicker = () => qDateProxy.value.hide();

const initialValues = ref<SeminarFormValues>({
  identifier: props.seminar?.identifier ?? "",
  date: props.seminar?.date ?? "",
  endDate: props.seminar?.endDate ?? "",
  name: props.seminar?.name ?? "",
  lector: props.seminar?.lector ?? "",
  accreditation: props.seminar?.accreditation ?? "",
  city: props.seminar?.city ?? "",
  duration: props.seminar?.duration ?? "8 hodin",
  price: props.seminar?.price ?? undefined,
  type: props.seminar?.type ?? SeminarType.Default,
  capacity: props.seminar?.capacity ?? undefined,
});

const emit = defineEmits<{
  (e: "submit", values: SeminarFormValues): void;
}>();

// There is some typescript error in vee-validate library, therefore we need to type this as any 😤
const onSubmit = (values: any) => {
  emit("submit", values as SeminarFormValues);
};
</script>

<style lang="scss"></style>
